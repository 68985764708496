import React from "react";
import FaqIcon from "../../components/icons/FaqIcon";
import ReferenceIcon from "../../components/icons/ReferenceIcon";
import TotalLeaveIcon from "../../components/icons/TotalLeaveIcon";
import TroubleshootingIcon from "../../components/icons/TroubleshootingIcon";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import { useNavigate } from "react-router-dom";

function Help() {
    const navigate = useNavigate();
    return (
        <div>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        name: "Help",
                    },
                ]}
            />
            <div>
                <h2 className="help-header">
                    Please select a topic to discover more
                </h2>
                <div className="help-subheader ">
                    <div className="d-flex help-card ">
                        <div
                            className=" d-flex section-card help-card-text"
                            onClick={() => {
                                navigate("/help/faq");
                            }}
                            tabIndex={0}
                        >
                            <FaqIcon />
                            Frequently asked questions (FAQ)
                        </div>
                        <div
                            className=" d-flex section-card help-card-text"
                            onClick={() => {
                                navigate("/help/referenceresources");
                            }}
                            tabIndex={0}
                        >
                            <ReferenceIcon />
                            <div>Reference resources</div>
                        </div>
                    </div>
                    <div className="d-none help-card ">
                        <div
                            className=" d-none section-card card-disable"
                            tabIndex={0}
                        >
                            <TotalLeaveIcon />
                            <div>Total Leave users important Info</div>
                        </div>
                        <div
                            className=" d-none section-card card-disable"
                            tabIndex={0}
                        >
                            <TroubleshootingIcon />
                            <div>Troubleshooting common issues</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;
