import React from "react";

function FaqIcon() {
    return (
        <svg
            width="30"
            height="25"
            viewBox="0 0 30 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 3C0 1.35938 1.3125 0 3 0H16.5C18.1406 0 19.5 1.35938 19.5 3V12C19.5 13.6875 18.1406 15 16.5 15H9.46875L5.67188 17.8594C5.4375 18.0469 5.15625 18.0469 4.875 17.9531C4.64062 17.8125 4.5 17.5781 4.5 17.25V15H3C1.3125 15 0 13.6875 0 12V3ZM12 16.5H16.5C18.9844 16.5 21 14.4844 21 12V6H27C28.6406 6 30 7.35938 30 9V18C30 19.6875 28.6406 21 27 21H25.5V23.25C25.5 23.5781 25.3125 23.8125 25.0781 23.9531C24.7969 24.0469 24.5156 24.0469 24.2812 23.8594L20.4844 21H15C13.3125 21 12 19.6875 12 18V16.5ZM6.375 4.35938L6.32812 4.40625C6.1875 4.875 6.42188 5.4375 6.9375 5.57812C7.40625 5.76562 7.92188 5.53125 8.10938 5.01562V4.96875C8.15625 4.82812 8.29688 4.78125 8.4375 4.78125H10.6875C11.0156 4.78125 11.2969 5.01562 11.2969 5.34375C11.2969 5.57812 11.1562 5.76562 11.0156 5.85938L9.28125 6.89062C8.95312 7.03125 8.8125 7.35938 8.8125 7.6875V8.20312C8.8125 8.71875 9.23438 9.14062 9.75 9.14062C10.2188 9.14062 10.6406 8.71875 10.6875 8.20312L11.9062 7.5C12.7031 7.07812 13.1719 6.23438 13.1719 5.34375C13.1719 3.98438 12.0469 2.90625 10.6875 2.90625H8.4375C7.5 2.90625 6.65625 3.46875 6.375 4.35938ZM10.9688 11.625C10.9688 10.9688 10.4062 10.4062 9.75 10.4062C9.04688 10.4062 8.48438 10.9688 8.48438 11.625C8.48438 12.3281 9.04688 12.8906 9.75 12.8906C10.4062 12.8906 10.9688 12.3281 10.9688 11.625Z"
                fill="#037CB7"
            />
        </svg>
    );
}

export default FaqIcon;
