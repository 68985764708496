import React, { useState, useEffect, useRef } from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    getSortedRowModel,
} from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import Pagination from "../../components/table/Pagination";
import sortIcon from "../../assets/icons/sort-icon.svg";
import ModifyViewButton from "./ModifyViewButton";
import NoRecords from "../../components/alerts/NoRecords";
import RequestFailed from "../../components/alerts/RequestFailed";
import Loader from "../../components/ui/Loader";
import ReportSavingPage from "./ReportSavingPage";
import Button from "../../components/ui/Button";
import ReportEditPage from "./ReportEditPage";
import { useNavigate } from "react-router-dom";
import GTM from "../../GTM";
import * as XLSX from "xlsx";
import Divider from "../../components/ui/Divider";
import useOutsideClick from "../../hooks/useOutsideClick";
import { absenceType, leaveType, paymentHistoryType } from "../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";

function ReportTable({
    claimData,
    defaultColumns,
    isError,
    isFetching,
    isUninitialized,
    handleOptionChange,
    allViewsData,
    handleViewReportSave,
    loaderStatus,
    handleViewReportUpdate,
    handleCoverageType,
    selectedReportView,
    notificationStatus,
    description,
    selectedViewId,
    handleViewID,
    reportViewType,
    reportLastFetched,
    userEmail,
    portalUserApiError,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportingPeriodValue,
    reportingStatusValue,
    selectedCoverageType,
    selectedPolicyDivision,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    periodStatusValue,
    protectionStatusValue,
    reportSortingColumnDirection,
    reportSortingColumnName,
    disclaimer,
    totalResults,
    portalUserData,
    totalPages ,
    pagecounter,
}) {
    const navigate = useNavigate();
    const gtm = new GTM(null);
    const [checkColumnOrder, setCheckColumnOrder] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState([]);
    const [columnOrderChange, setColumnOrderChange] = useState(true);
    const [showEditPage, setShowEditPage] = useState(false);
    const [sortingColumnDetails, setSortingColumnDetails] = useState();
    const [reportViewDropDownValues, setReportViewDropDownValues] = useState(
        []
    );
    const [updateViewDetails, setUpdateViewDetails] = useState([]);
    const [selectedReport, setselectedReport] = useState(null);
    const [selectedViewDataType, setSelectedViewDataType] =
        useState(reportViewType);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [columnResizeMode, setColumnResizeMode] = useState("onChange");

    const [ssnDisplayMode, setSSNDisplayMode] = useState(0);
    const { pagingCount } = useFlags();

    const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
        setColumnOrderChange(false);
        columnOrder.splice(
            columnOrder.indexOf(targetColumnId),
            0,
            columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
        );
        return [...columnOrder];
    };

    const [columns, setColumns] = useState([...defaultColumns]);

    const DraggableColumnHeader = ({ header, table }) => {
        const { getState, setColumnOrder } = table;
        const { columnOrder } = getState();
        setCheckColumnOrder(columnOrder);
        const { column } = header;
        const [, dropRef] = useDrop({
            accept: "column",
            drop: (draggedColumn) => {
                const newColumnOrder = reorderColumn(
                    draggedColumn.id,
                    column.id,
                    columnOrder
                );
                setColumnOrder(newColumnOrder);
                // Check if the first column was moved to the last position
                if (newColumnOrder[0] === columnOrder[columnOrder.length - 1]) {
                    scrollToHorizontal();
                }
            },
        });

        const [{ isDragging }, dragRef] = useDrag({
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            item: () => column,
            type: "column",
        });

        const toggleSSNVisibility = () => {
            let newMode;

            if (organizationsAccess.SSNACCESS === "Partial") {
                newMode = (ssnDisplayMode + 1) % 2;
            } else if (organizationsAccess.SSNACCESS === "Full") {
                newMode = (ssnDisplayMode + 1) % 3;
            }

            setSSNDisplayMode(newMode);
        };

        const getSSNIcon = () => {
            let icon = null;

            switch (organizationsAccess?.SSNACCESS) {
                case "No Access":
                    break;
                case "Partial":
                    switch (ssnDisplayMode) {
                        case 0:
                            icon = (
                                <i
                                    className="fa fa-eye mask-eye cursor-pointer ml-4p"
                                    style={{ color: "#037cb7" }}
                                ></i>
                            );
                            break;
                        case 1:
                            icon = (
                                <i
                                    className="fa fa-eye-slash full-eye cursor-pointer ml-4p"
                                    aria-hidden="true"
                                ></i>
                            );
                            break;
                        default:
                            break;
                    }
                    break;
                case "Full":
                    switch (ssnDisplayMode) {
                        case 0:
                            icon = (
                                <i
                                    className="fa fa-eye part-eye cursor-pointer report-ssn-icon ml-4p"
                                    style={{ color: "#037cb7" }}
                                ></i>
                            );
                            break;
                        case 1:
                            icon = (
                                <i className="fa fa-eye mask-eye cursor-pointer ml-4p"></i>
                            );
                            break;
                        case 2:
                            icon = (
                                <i
                                    className="fa fa-eye-slash full-eye cursor-pointer ml-4p"
                                    style={{ color: "#037cb7" }}
                                    aria-hidden="true"
                                ></i>
                            );
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }

            return icon;
        };

        const scrollToHorizontal = () => {
            const tableContainer = document.querySelector(
                ".custom-table-container"
            );
            if (!tableContainer) return;
            const scrollAmount =
                tableContainer.scrollWidth - tableContainer.clientWidth;
            tableContainer.scrollTo({ left: scrollAmount, behavior: "smooth" });
        };

        return (
            <th
                ref={dropRef}
                colSpan={header.colSpan}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    // width: header.getSize(),
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.getSize(),
                    paddingLeft: 24,
                }}
            >
                <div
                    className="report-header d-flex"
                    // style={{ width: header.column.columnDef.minSize }}
                >
                    <div
                        ref={dragRef}
                        onClick={header.column.getToggleSortingHandler()}
                        onKeyDown={(e) => {
                            if (
                                e.key === "Enter" &&
                                header.column.getToggleSortingHandler
                            ) {
                                header.column.getToggleSortingHandler()(e);
                            }
                        }}
                        className="ml-4p"
                        style={{
                            backgroundColor: "white",
                            wordBreak: "break-all",
                            textWrap: "nowrap",
                        }}
                    >
                        {header.isPlaceholder
                            ? null
                            : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                              )}
                        {sorting !== undefined &&
                        sorting.length > 0 &&
                        sorting[0].id === column.id ? (
                            sorting[0].desc ? (
                                <i
                                    style={{ marginLeft: 4, color: "#025780" }}
                                    className="fa-solid fa-arrow-down-wide-short "
                                ></i>
                            ) : (
                                <i
                                    style={{ marginLeft: 4, color: "#025780" }}
                                    className="fa-solid fa-arrow-up-short-wide"
                                ></i>
                            )
                        ) : (
                            <img src={sortIcon} alt="Sort" />
                        )}
                    </div>
                    <div>
                        <div>
                            {header.column.id === "SSN" && (
                                <div
                                    onClick={toggleSSNVisibility}
                                    style={{ cursor: "pointer" }}
                                >
                                    {!isUninitialized &&
                                        !isFetching &&
                                        !isError &&
                                        data.length > 0 &&
                                        getSSNIcon()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    onDoubleClick={header.getResizeHandler()}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${
                        header.column.getIsResizing() ? "isResizing" : ""
                    }`}
                />
            </th>
        );
    };

    const [data, setData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [isfetchingMoreData, setIsFectchingMoreDate] = useState(false);
    
    useEffect(() => {
        setData(claimData);
    }, [claimData]);

    useEffect(() => {
        if (totalPages  > pagecounter) {
            setIsFectchingMoreDate(true);
        }
        if (totalPages  <= pagecounter){
            setIsFectchingMoreDate(false);
        }
    }, [totalPages ,pagecounter]);

    const [columnOrder, setColumnOrder] = useState(
        columns.map((column) => column.id)
    );

    useEffect(() => {
        setColumns([...defaultColumns]);
    }, [defaultColumns]);

    const [sorting, setsorting] = useState([
        { id: reportSortingColumnName, desc: reportSortingColumnDirection },
    ]);

    useEffect(() => {
        setsorting([
            { id: reportSortingColumnName, desc: reportSortingColumnDirection },
        ]);
    }, [reportSortingColumnName, reportSortingColumnDirection]);

    const [showLoader, setShowLoader] = useState(false);
    const [show, setShow] = useState(false);

    const handleItemsPerPageChange = (selectedReport) => {
        setItemsPerPage(selectedReport);
        setPageNumber(0);
    };
    useEffect(() => {
        setPageNumber(0);
    }, [isFetching]);
    const handlePageNumberClick = (pageNumber) => {
        setPageNumber(pageNumber - 1);
    };
    const handleSortingChange = (newSorting) => {
        setsorting(newSorting);
    };
    const table = useReactTable({
        data,
        columns: columns.map((column) => ({
            ...column,
            // width: column.size,
        })),

        state: {
            sorting,
            columnOrder,
            pagination: {
                pageIndex: pageNumber,
                pageSize: itemsPerPage,
            },
            columnVisibility,
        },
        columnResizeMode,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
        onSortingChange: handleSortingChange,
    });

    // setting defult view in dropdown
    useEffect(() => {
        const keyValuePair = allViewsData.map((obj) => {
            return {
                value: obj.Name,
                label: obj.Name,
                key: obj.ViewId,
            };
        });
        setselectedReport(keyValuePair[0]);

        const coverageType = allViewsData.map((obj) => obj.CoverageType)[0];
        handleCoverageType(coverageType);
    }, []);

    // selecting dropdown value
    const handleSelectChange = (selectedReport) => {
        handleOptionChange(selectedReport);
        setselectedReport(selectedReport);

        setIsDropdownOpen(!isDropdownOpen);
        setSSNDisplayMode(0);
        setsorting([
            { id: reportSortingColumnName, desc: reportSortingColumnDirection },
        ]);
        const matchedObject = allViewsData.find(
            (obj) => obj.ViewId === selectedReport.key
        );
        setColumnOrder(matchedObject.ColumnInformation.map((obj) => obj.id));
        handleCoverageType(matchedObject.CoverageType);
        emitFiltersDataForSelectedView(matchedObject);
        setSelectedViewDataType(matchedObject.DataViewType);
        gtm.Event(
            "custom_event",
            "dropdown",
            "select",
            "change report",
            "report detail",
            matchedObject.DataViewType,
            "field_interaction",
            ""
        );
    };

    useEffect(() => {
        if (selectedReport) {
            handleViewID(selectedReport.key);
        }
    }, [handleViewID, selectedReport]);

    // hidecolumns

    useEffect(() => {
        if (selectedReport && selectedReport !== undefined) {
            const matchedObject = allViewsData.find(
                (obj) => obj?.ViewId === selectedReport?.key
            );

            const getColumnVisibility = matchedObject?.ColumnInformation;

            if (getColumnVisibility && getColumnVisibility !== undefined) {
                const hide = {};

                getColumnVisibility.forEach((item) => {
                    hide[item.id] = item.checked;
                });

                setColumnVisibility(hide);
            }
        }
    }, [allViewsData, selectedReport]);

    //used for onclick on report on most used reports
    useEffect(() => {
        if (selectedReportView && selectedReportView !== undefined) {
            const keyValuePair = {
                value: selectedReportView.Name,
                label: selectedReportView.Name,
                key: selectedReportView.ViewId,
            };
            setColumnOrder(
                selectedReportView.ColumnInformation.map((obj) => obj.Id)
            );
            setselectedReport(keyValuePair);
            handleCoverageType(selectedReportView.CoverageType);
            emitFiltersDataForSelectedView(selectedReportView);
        }
    }, [selectedReportView]);

    const handleResetButton = () => {
        setColumnOrderChange(true);
        setColumnOrder(columns.map((column) => column.id));
        const resetColumnOrder = {};
        defaultColumns.forEach((item) => {
            resetColumnOrder[item.id] = item.checked;
        });
        setColumnVisibility(resetColumnOrder);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "reset",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleSaveButton = () => {
        setShow(true);
        const matchedObject = allViewsData.find(
            (obj) =>
                obj.ViewId === selectedReport.key &&
                obj.OwnerType !== "Standard"
        );
        if (matchedObject && matchedObject.OwnerType === "Personal") {
            setShowEditPage(true);
            setUpdateViewDetails(matchedObject);
        } else {
            setShowEditPage(false);
        }
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "save",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const handleReportViewDontSave = () => {
        setShow(false);
    };

    const handleReportViewSave = async (viewName, viewDescription) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.find((obj) => obj.id === id);
                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );

                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        setColumnOrderChange(true);
        setShow(false);
        handleViewReportSave(
            changedColumnSorting,
            changedColumnOrder,
            viewName,
            viewDescription
        );
        const savedViewValue = {
            value: viewName,
            label: viewName,
        };
        setselectedReport(savedViewValue);
    };

    useEffect(()=>{
        setselectedReport((prev)=>({
            ...prev,
            key:selectedViewId,
        }))
    },[selectedViewId])

    useEffect(() => {
        let keyValuePair;
        if (selectedReportView && selectedReportView.DataViewType) {
            keyValuePair = allViewsData
                .filter(
                    (obj) =>
                        obj.DataViewType === selectedReportView.DataViewType
                )
                .map((obj) => {
                    return {
                        value: obj.Name,
                        label: obj.Name,
                        key: obj.ViewId,
                        ownerType: obj.OwnerType,
                    };
                });
        } else {
            // If selectedReportView is empty, map allViewsData without filtering
            keyValuePair = allViewsData.map((obj) => {
                return {
                    value: obj.Name,
                    label: obj.Name,
                    key: obj.ViewId,
                    ownerType: obj.OwnerType,
                };
            });
        }
        setReportViewDropDownValues(keyValuePair);
    }, [allViewsData, selectedReportView]);

    useEffect(() => {
        if (loaderStatus === true) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [loaderStatus]);

    const handleReportViewUpdate = (viewName, viewDescription, viewId) => {
        const changedColumnSorting = checkColumnOrder
            .map((id, index) => {
                const matchedObject = sorting.find((obj) => obj.id === id);
                if (matchedObject) {
                    return {
                        ColumnDisplayOrderId: index + 1,
                        Direction: matchedObject.desc ? "DESC" : "ASC",
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        const changedColumnOrder = checkColumnOrder
            .map((id, index) => {
                const matchedObject = defaultColumns.find(
                    (obj) => obj.id === id
                );
                if (matchedObject) {
                    return {
                        DisplayOrder: index + 1,
                        AccessorKey: matchedObject.accessorKey,
                        Id: matchedObject.id,
                        Header: matchedObject.header,
                        Checked:
                            columnVisibility[id] === undefined
                                ? true
                                : columnVisibility[id],
                        LockedDisplay: matchedObject.enableHiding,
                        Size: matchedObject.size,
                        MinSize: matchedObject.minSize,
                        MaxSize: matchedObject.maxSize,
                    };
                } else {
                    return null;
                }
            })
            .filter((obj) => obj !== null);
        handleViewReportUpdate(
            changedColumnSorting,
            changedColumnOrder,
            updateViewDetails,
            viewName,
            viewDescription
        );
        setShow(false);
        const savedViewValue = {
            value: viewName,
            label: viewName,
            key: viewId,
        };
        setselectedReport(savedViewValue);
    };

    const handleManageReportsClick = () => {
        let data = [];
        navigate("/reportingcapabilities/manageviews", {
            state: { data, portalUserData, isError },
        });
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "manage my views",
            "report detail",
            selectedViewDataType,
            "navigation_click",
            ""
        );
    };

    useEffect(() => {
        const res = {};
        for (const item of defaultColumns) {
            const { id, checked } = item;
            if (columnVisibility.hasOwnProperty(id)) {
                if (columnVisibility[id] !== checked) {
                    res[id] = checked;
                }
            }
        }
        if (Object.keys(res).length > 0) {
            setColumnOrderChange(false);
        } else {
            setColumnOrderChange(true);
        }
    }, [columnVisibility]);

    useEffect(() => {
        if (sorting[0]) {
            gtm.Event(
                "custom_event",
                "button",
                "click",
                "sort",
                "report detail",
                selectedViewDataType,
                "field_interaction",
                sorting[0].id
            );
        }
        const isMatching = sorting.some(
            (item) =>
                item.id === reportSortingColumnName &&
                item.desc === reportSortingColumnDirection
        );
        setSortingColumnDetails(isMatching);
    }, [sorting]);

    const dropdownRef = useRef(null);
    const toggleRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const isClickedOutside = useOutsideClick(dropdownRef, toggleRef);

    useEffect(() => {
        if (isClickedOutside) {
            setIsDropdownOpen(false);
        }
    }, [isClickedOutside]);

    const groupedOptions = {};
    reportViewDropDownValues.forEach((option) => {
        if (!groupedOptions[option.ownerType]) {
            groupedOptions[option.ownerType] = [];
        }
        groupedOptions[option.ownerType].push(option);
    });

    const csvData = data.map((row) => {
        const rowData = {};

        defaultColumns.forEach((column) => {
            rowData[column.id] = row[column.accessorKey];
        });

        return rowData;
    });

    const dataSets = [{ tableHeader: csvData }];

    const secondPageData = [
        ["Report", selectedReport?.label],

        [],

        ["Date export run", reportLastFetched],

        [],
        ["Run by user", userEmail],
        [],
        [("Filter", "Filter Values")],
    ];
    if (!portalUserApiError) {
        secondPageData?.push(["Reporting period", reportingPeriodValue.value]);

        const periodRange = reportingPeriodValue.label.split("-", 4);
        secondPageData?.push([
            "From date",
            periodRange[0].substring(periodRange[0].length - 11),
        ]);
        secondPageData?.push([
            "To date",
            reportingPeriodValue.label.substring(
                reportingPeriodValue.label.length - 11
            ),
        ]);
        secondPageData?.push([
            "Coverage type",
            selectedCoverageType.map((item) => item.value).join(", "),
        ]);
        secondPageData?.push([
            selectedViewDataType === "Payments" ? "Payment status" : "Status",
            reportingStatusValue.map((item) => item.value).join(", "),
        ]);
        // if (secondPageData && selectedViewDataType !== "Leave") {
        //     secondPageData.push([
        //         "Policies and Divisions",
        //         selectedPolicyDivision
        //             ?.filter((item) => item?.includes("-"))
        //             .join(", "),
        //     ]);
        // }

        if (
            secondPageData &&
            (selectedViewDataType === leaveType ||
                selectedViewDataType === absenceType)
        ) {
            secondPageData.push([
                "Include Historical Leave",
                includeHistoricalLeaveValue?.label || "",
            ]);

            secondPageData?.push([
                "Period Schedule Type",
                Array.isArray(periodScheduleTypeValue)
                    ? periodScheduleTypeValue
                          .map((item) => item?.value)
                          .join(", ")
                    : "",
            ]);

            const periodStatusString = Array.isArray(periodStatusValue)
                ? periodStatusValue.map((item) => item?.value).join(", ")
                : "";

            const protectionStatusString = Array.isArray(protectionStatusValue)
                ? protectionStatusValue.map((item) => item?.value).join(", ")
                : "";

            secondPageData?.push(["Period Status", periodStatusString]);
            secondPageData?.push(["Protection Status", protectionStatusString]);
        }
        secondPageData?.push([" "]);
        secondPageData?.push(["Disclaimer", disclaimer]);
    }

    const sheetNames = ["Data", "Filter criteria"];

    const ssnValues = table
        .getRowModel()
        .rows.map((row) => row.renderValue("SSN"));

    const exportToExcel = () => {
        const data = () => {
            let rowModel = table.getSortedRowModel().rows;

            let tableData = rowModel.map((row) => {
                return row.getVisibleCells().map((cell) => {
                    let id = cell.column.id;
                    let val = cell.getValue();
                    return { [id]: val };
                });
            });
            return tableData;
        };
        const filterData = (columnVisibility, columnOrder, ssnDisplayMode) => {
            // Include only the columns specified in columnOrder and that are visible
            const keysToInclude = columnOrder.filter(
                (key) => columnVisibility[key]
            );

            const ssnAccess = organizationsAccess.SSNACCESS;
            const filteredData = [];
            const headerRow = keysToInclude.map((key) => key);
            filteredData.push(headerRow);
            data().forEach((row) => {
                const filteredRow = [];
                keysToInclude.forEach((key) => {
                    let cellValue = row.find((x) => x[key]);
                    if (key === "SSN") {
                        if (ssnAccess === "No Access") {
                            filteredRow.push("No Access");
                        } else {
                            if (ssnDisplayMode === 0) {
                                filteredRow.push("***-**-****");
                            } else if (ssnDisplayMode === 1) {
                                filteredRow.push(
                                    cellValue
                                        ? cellValue[key]?.substr(-4) || ""
                                        : ""
                                );
                            } else if (ssnDisplayMode === 2) {
                                filteredRow.push(
                                    cellValue ? cellValue[key] || "" : ""
                                );
                            } else {
                                filteredRow.push("");
                            }
                        }
                    } else {
                        filteredRow.push(cellValue ? cellValue[key] : "");
                    }
                });
                filteredData.push(filteredRow);
            });
            return filteredData;
        };

        const wb = XLSX.utils.book_new();

        dataSets.forEach((dataSet, index) => {
            const filteredData = filterData(
                columnVisibility,
                columnOrder,
                ssnDisplayMode
            );

            const ws = XLSX.utils.aoa_to_sheet(filteredData);
            XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
        });

        const secondPageWs = XLSX.utils.aoa_to_sheet(secondPageData);
        XLSX.utils.book_append_sheet(wb, secondPageWs, sheetNames[1]);

        XLSX.writeFile(wb, `${selectedReport?.label}.xlsx`);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "export to csv",
            "report detail",
            selectedViewDataType,
            "export_report",
            ""
        );
    };

    const getSSNDisplay = (cell, index) => {
        // Define a mapping object for organizationsAccess.SSNACCESS levels
        const ssnAccessLevels = {
            "No Access": () => {
                if (cell.column.id === "SSN") {
                    return "No Access";
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Partial: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
            Full: () => {
                if (cell.column.id === "SSN") {
                    if (ssnDisplayMode === 0) {
                        return "***-**-****";
                    } else if (ssnDisplayMode === 1) {
                        return ssnValues[index]?.substr(-4);
                    } else if (ssnDisplayMode === 2) {
                        return ssnValues[index];
                    } else {
                        return "";
                    }
                }
                return flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                );
            },
        };

        const displaySSN = ssnAccessLevels[organizationsAccess?.SSNACCESS];

        return displaySSN
            ? displaySSN()
            : flexRender(cell.column.columnDef.cell, cell.getContext());
    };

    const topScrollbarRef = useRef(null);
    const tableContentRef = useRef(null);
    const topScrollBarContentRef = useRef(null);
    let getCenterTotalSize = table.getCenterTotalSize();

    useEffect(() => {
        const topScrollbar = topScrollbarRef.current;
        const tableContent = tableContentRef.current;
        if (!topScrollbar || !tableContent) {
            return;
        }
        const syncScrollFromTable = () => {
            if (topScrollbar && tableContent) {
                topScrollbar.scrollLeft = tableContent.scrollLeft;
            }
        };

        const syncScrollFromTopScrollbar = () => {
            if (topScrollbar && tableContent) {
                tableContent.scrollLeft = topScrollbar.scrollLeft;
            }
        };

        const updateScrollbarWidth = () => {
            if ((topScrollbar && tableContent) || getCenterTotalSize) {
                topScrollbar.firstChild.style.width = `${getCenterTotalSize}px`;
            }
        };

        // Initial width update
        updateScrollbarWidth();

        // Adding event listeners for scroll synchronization
        tableContent.addEventListener("scroll", syncScrollFromTable);
        topScrollbar.addEventListener("scroll", syncScrollFromTopScrollbar);

        // Cleanup event listeners on component unmount
        return () => {
            tableContent.removeEventListener("scroll", syncScrollFromTable);
            topScrollbar.removeEventListener(
                "scroll",
                syncScrollFromTopScrollbar
            );
        };
    }, [topScrollbarRef, tableContentRef, isFetching, showLoader]);

    return (
        <div
            className="section-card row d-flex "
            style={{ padding: 0, margin: 0 }}
        >
            {showLoader ? (
                <Loader text="Saving" />
            ) : (
                <div className="p-0">
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                        }}
                    >
                        <div
                            className="d-flex justify-content-between report-table"
                            style={
                                isFetching || isfetchingMoreData
                                    ? { pointerEvents: "none", opacity: "0.4" }
                                    : {}
                            }
                        >
                            <div className="d-flex" style={{ gap: 8 }}>
                                <div
                                    className="report-view-dropdown"
                                    ref={dropdownRef}
                                >
                                    <div
                                        className="d-flex justify-content-between  align-items-center cursor-pointer"
                                        style={{ width: 320 }}
                                        ref={toggleRef}
                                        onClick={toggleDropdown}
                                    >
                                        <div className="dropdown-header">
                                            {selectedReport?.label
                                                ? selectedReport?.label
                                                : "select"}
                                        </div>
                                        <i
                                            className="fa-solid fa-chevron-down"
                                            style={{ fontSize: 16 }}
                                        ></i>
                                    </div>
                                    {isDropdownOpen && (
                                        <div
                                            className="section-card custom-scrollbar dropdowm-container"
                                            style={{ borderRadius: 4 }}
                                        >
                                            {Object.entries(groupedOptions).map(
                                                (
                                                    [ownerType, options],
                                                    index
                                                ) => (
                                                    <div
                                                        key={index}
                                                        className="dropdown-section"
                                                    >
                                                        <h6 className="fw-bold">
                                                            {ownerType}
                                                        </h6>
                                                        <ul className="dropdown-options">
                                                            {options.map(
                                                                (
                                                                    option,
                                                                    idx
                                                                ) => (
                                                                    <li
                                                                        className={
                                                                            selectedReport &&
                                                                            selectedReport.value ===
                                                                                option.value
                                                                                ? "selected-option"
                                                                                : ""
                                                                        }
                                                                        key={
                                                                            idx
                                                                        }
                                                                        onClick={() =>
                                                                            handleSelectChange(
                                                                                option
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                        {index !==
                                                            Object.keys(
                                                                groupedOptions
                                                            ).length -
                                                                1 && (
                                                            <Divider
                                                                className="mb-0p"
                                                                mx="mt-0p "
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                                <ModifyViewButton
                                    table={table}
                                    selectedViewDataType={selectedViewDataType}
                                />

                                <div className="data-view-button">
                                    <Button
                                        style={{ height: 28 }}
                                        text="Save"
                                        icon="fa-solid fa-floppy-disk"
                                        outline={true}
                                        onClick={() => {
                                            handleSaveButton();
                                        }}
                                        disabled={
                                            sortingColumnDetails &&
                                            (selectedFilterStatus === false
                                                ? selectedFilterStatus
                                                : columnOrderChange)
                                        }
                                        pageType="reportSaving"
                                    ></Button>
                                </div>
                                <div className="data-view-button">
                                    <Button
                                        style={{ height: 28 }}
                                        text="Reset"
                                        icon="fa-solid fa-arrow-rotate-left"
                                        outline={true}
                                        onClick={() => {
                                            handleResetButton();
                                        }}
                                        disabled={
                                            selectedFilterStatus === false
                                                ? selectedFilterStatus
                                                : columnOrderChange
                                        }
                                        pageType="reportSaving"
                                    ></Button>
                                </div>
                            </div>
                            <div
                                className="manage-my-views"
                                onClick={handleManageReportsClick}
                            >
                                <span className="mr-8p">Manage my reports</span>
                                <i className="fa-solid fa-user-cog"></i>
                            </div>

                            {isFetching ? (
                                <>
                                    <div className="vertical-divider"></div>
                                    <div className="disabled-text">
                                        <span className="d-inline-block mx-4p fw-bold">
                                            Loading
                                            <i className="fa-solid fa-download pl-8p"></i>
                                        </span>
                                    </div>
                                </>
                            ) : (
                                data.length > 0 && (
                                    <>
                                        <div className="vertical-divider"></div>
                                        <div
                                            onClick={exportToExcel}
                                            className="export-to-excel"
                                        >
                                            Export data
                                        </div>
                                    </>
                                )
                            )}
                            </div>
                            <p className="report-header">{description}</p>
                            {(totalPages > pagecounter) && (
                                <div className="total-table">
                                    <div className="spinner"></div>
                                    &nbsp;Retrieving {pagecounter * pagingCount} of {totalResults} records.
                                </div>
                            )}
                    </div>
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <div
                            className="custom-table-container"
                            style={{ paddingBottom: data.length > 20 ? 0 : 16 }}
                        >
                            <div
                                className="custom-topscrollbar"
                                ref={topScrollbarRef}
                            >
                                {/* Render some content to visualize the scrollbar */}
                                <div
                                    className="scrollbar-content"
                                    style={{
                                        width: getCenterTotalSize,
                                        height: "15px",
                                    }}
                                    ref={topScrollBarContentRef}
                                >
                                    {/* Actual scrollbar will be managed by CSS */}
                                </div>
                            </div>
                            <div
                                className="custom-table-content"
                                ref={tableContentRef}
                            >
                                <table
                                    {...{
                                        style: {
                                            width: getCenterTotalSize,
                                        },
                                    }}
                                >
                                    <thead>
                                        {table
                                            .getHeaderGroups()
                                            .map((headerGroup) => (
                                                <tr key={headerGroup.id}>
                                                    {headerGroup.headers.map(
                                                        (header) => (
                                                            <DraggableColumnHeader
                                                                key={header.id}
                                                                header={header}
                                                                table={table}
                                                            />
                                                        )
                                                    )}
                                                </tr>
                                            ))}
                                    </thead>
                                    {!isError && (
                                        <tbody>
                                            {table
                                                .getRowModel()
                                                .rows.map((row, index) => (
                                                    <tr
                                                        className={
                                                            (index % 2 === 0
                                                                ? "even "
                                                                : "odd ") +
                                                            (index === 0
                                                                ? "first "
                                                                : "")
                                                        }
                                                        key={row.id}
                                                    >
                                                        {row
                                                            .getVisibleCells()
                                                            .map((cell) => (
                                                                <td
                                                                    key={
                                                                        cell.id
                                                                    }
                                                                    style={{
                                                                        width: cell.column.getSize(),
                                                                        paddingLeft: 24,
                                                                        wordBreak:
                                                                            "break-all",
                                                                    }}
                                                                    className="td"
                                                                >
                                                                    {getSSNDisplay(
                                                                        cell,
                                                                        index
                                                                    )}
                                                                </td>
                                                            ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    )}
                                </table>
                                {!isUninitialized &&
                                    !isFetching &&
                                    !isError &&
                                    data.length === 0 && (
                                        <div
                                            style={{
                                                paddingLeft: 24,
                                                paddingRight: 24,
                                                paddingTop: 24,
                                            }}
                                        >
                                            <NoRecords
                                                page="search"
                                                label="search"
                                                attribute1="search"
                                                attribute3="search_error"
                                            />
                                        </div>
                                    )}
                                {!isUninitialized && isError && (
                                    <div
                                        style={{
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                            marginTop: -6,
                                            paddingBottom: 24,
                                        }}
                                    >
                                        <RequestFailed
                                            tabIndex={0}
                                            message={
                                                "Your request failed, please try again or contact us for further assistance."
                                            }
                                            label="search"
                                            attribute1="employee search"
                                            attribute3="employee_error"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {data.length > 20 && !isFetching && (
                        <div className="py-20p">
                            <Pagination
                                pageCount={table.getPageCount()}
                                gotoPage={(index) =>
                                    handlePageNumberClick(index + 1)
                                }
                                setPageSize={handleItemsPerPageChange}
                                pageSize={itemsPerPage}
                                options={[20, 50, 100]}
                            />
                        </div>
                    )}
                    {
                        <ReportSavingPage
                            onShow={show}
                            onDontSave={handleReportViewDontSave}
                            onSave={handleReportViewSave}
                            reportViewType={selectedViewDataType}
                        />
                    }
                    {showEditPage ? (
                        <ReportEditPage
                            onShow={show}
                            onDontSave={handleReportViewDontSave}
                            onSave={handleReportViewUpdate}
                            onSaveAs={handleReportViewSave}
                            notification={notificationStatus}
                            reportDetails={updateViewDetails}
                        />
                    ) : (
                        <ReportSavingPage
                            onShow={show}
                            onDontSave={handleReportViewDontSave}
                            onSave={handleReportViewSave}
                            notification={notificationStatus}
                            reportViewType={selectedViewDataType}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
export default ReportTable;
