import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";
import { Modal } from "react-bootstrap";
import mainLogo from "../assets/images/unum-Insights-logo.svg";
import firstLoginImage from "../assets/images/first-login-image.svg";
import { setLoginMessage } from "../slices/preferenceSlice";
import { Parser } from "html-to-react";

const FirstLoginMessage = ({ message }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const htmlToReactParser = new Parser();

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    const handleHelpButtonClick = useCallback(() => {
        navigate("/help/referenceresources");
        dispatch(setLoginMessage(""));
        setShow(false);
    }, [navigate]);

    const handleContinueButtonClick = useCallback(() => {
        navigate("/search");
        dispatch(setLoginMessage(""));
        setShow(false);
    }, [navigate]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            size="xl"
            backdrop="static"
        >
            <Modal.Body style={{ padding: 0 }}>
                <div>
                    <div className="d-flex">
                        <div className="d-flex first-login">
                            <img
                                src={mainLogo}
                                alt="logo"
                                style={{ width: 284 }}
                            />
                            <label className="first-login-text">
                                {htmlToReactParser.parse(message)}
                            </label>
                            <div
                                className="d-flex"
                                style={{ gap: 12, height: 64 }}
                            >
                                <div className="buttontext myinsightbutton first-login-buttons">
                                    <Button
                                        onClick={handleHelpButtonClick}
                                        text="Help me get started"
                                    />
                                </div>
                                <div className="buttontext myinsightbutton first-login-buttons">
                                    <Button
                                        onClick={handleContinueButtonClick}
                                        text="Continue to Insights"
                                        outline={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <img
                                src={firstLoginImage}
                                className={{ right: 0 }}
                                alt="Login Message Image"
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default FirstLoginMessage;
