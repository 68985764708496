import React, { useState } from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";

export default function SavedReportEditDialogBox({
    onShow,
    onSave,
    onDontSave,

    reportName,
    onViewNameChange,
    onDescriptionChange,
    reportDescription,
    selectedView,
}) {
    const [descriptionValue, setDescriptionValue] = useState();

    const gtm = new GTM(null);

    const handleGtmTags = (label, attribute3) => {
        gtm.Event(
            "manage my reports",
            "button",
            "click",
            label,
            "report detail",
            selectedView.DataViewType.toLowerCase(),
            attribute3,
            ""
        );
    };
    const sendSaveEvent = () => {
        onSave(reportName, descriptionValue);

        handleGtmTags("update", "update_name");
    };
    const sendDontSaveEvent = () => {
        onDontSave();
        handleGtmTags("cancle deletion", "field_interaction");
    };

    const handleViewNameChange = ({ target: input }) => {
        onViewNameChange(input.value);
    };

    const handleDescriptionValueChange = ({ target: input }) => {
        onDescriptionChange(input.value);
    };

    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
            >
                <Modal.Header>
                    <SectionHeading heading="Editing" />
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        onClick={sendDontSaveEvent}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>Report name</label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add a name"
                            onChange={handleViewNameChange}
                            value={reportName}
                        />
                    </div>
                    <div>
                        <div>
                            <label
                                style={{
                                    paddingTop: 12,
                                }}
                            >
                                Description (Optional)
                            </label>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Add a description"
                            onChange={handleDescriptionValueChange}
                            value={reportDescription}
                        />
                    </div>
                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Cancel"
                                text="Cancel"
                                outline={true}
                                onClick={sendDontSaveEvent}
                            />
                            <Button text="Update" onClick={sendSaveEvent} disabled={reportName?.length>0?false:true}/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
