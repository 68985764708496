import React from "react";
import SortIcon from "./SortIcon";

function Header({ headerGroups }) {
    return (
        <div className="header">
            {headerGroups.map((headerGroup, index) => (
                <React.Fragment key={index}>
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                        {headerGroup.headers.map((column, idx) => {
                            return (
                                <div
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="th"
                                    key={idx}
                                    tabIndex="0"
                                >
                                    {column.render("Header")}
                                </div>
                            );
                        })}
                    </div>

                    <div
                        {...headerGroup.getHeaderGroupProps({
                            key: "headerSubgroup_" + index,
                        })}
                        className="tr"
                    >
                        {headerGroup.headers.map((column, idx) => {
                            return (
                                <div
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    className="th"
                                    key={idx}
                                >
                                    <SortIcon column={column} />
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
}

export default Header;
