import React, { useState, useEffect } from "react";
import GTM from "../../GTM";

function ModifyViewButton({ table, selectedViewDataType }) {
    const [isDropdownVisible, setDropdownVisibility] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const gtm = new GTM(null);
    const toggleDropdown = () => {
        setDropdownVisibility(!isDropdownVisible);
        gtm.Event(
            "custom_event",
            "button",
            "click",
            "modify view",
            "report detail",
            selectedViewDataType,
            "field_interaction",
            ""
        );
    };

    const closeDropdown = (event) => {
        if (!event.target.matches(".modify-view")) {
            setDropdownVisibility(false);
            setSearchQuery("");
        }
    };

    useEffect(() => {
        window.addEventListener("click", closeDropdown);
    });

    const filteredColumns = table
        .getAllLeafColumns()
        .filter((column) =>
            column.id.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const clearSearch = () => {
        setSearchQuery("");
    };
    return (
        <div
            className="col-lg-2 col-sm-2 col-2"
            style={{ width: "fit-content" }}
        >
            <div className="dropdown">
                <button
                    className="modify-view"
                    onClick={toggleDropdown}
                    tabIndex={0}
                >
                    Modify <i className="fas fa-edit"></i>
                </button>
                <div
                    id="myDropdown"
                    className={`dropdown-content  ${
                        isDropdownVisible ? "show" : ""
                    }`}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.stopPropagation();
                        }
                    }}
                    tabIndex={0}
                >
                    <div className="dropdown-header">Columns displayed</div>
                    <div className="search-container">
                        <i className="fa-solid fa-magnifying-glass icon mx-4p"></i>
                        <input
                            className="dropdown-search"
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            tabIndex={0}
                        />
                        {searchQuery && (
                            <div
                                className="icon cursor-pointer"
                                onClick={clearSearch}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                        )}
                    </div>

                    <div
                        className="custom-scrollbar"
                        style={{
                            maxHeight: 200,
                        }}
                    >
                        {filteredColumns.length === 0 && (
                            <div className="d-flex justify-content-center my-8p text-center">
                                No options
                            </div>
                        )}

                        <div>
                            {filteredColumns.length > 0 && (
                                <div>
                                    <label className="dropdown-list">
                                        <input
                                            type="checkbox"
                                            checked={table.getIsAllColumnsVisible()}
                                            onChange={table.getToggleAllColumnsVisibilityHandler()}
                                        />{" "}
                                        Select All
                                    </label>
                                </div>
                            )}

                            {filteredColumns.map((column) => (
                                <div
                                    key={column.id}
                                    style={{
                                        ...(column.getCanHide()
                                            ? {}
                                            : {
                                                  pointerEvents: "none",
                                              }),
                                        ...(column.getIsVisible()
                                            ? {
                                                  cursor: "pointer",
                                              }
                                            : {}),
                                    }}
                                >
                                    <label className="dropdown-list">
                                        <input
                                            type="checkbox"
                                            checked={column.getIsVisible()}
                                            onChange={column.getToggleVisibilityHandler()}
                                            style={
                                                column.getCanHide()
                                                    ? {}
                                                    : {
                                                          backgroundColor:
                                                              "#AAAAAA",
                                                      }
                                            }
                                        />{" "}
                                        {column.id}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModifyViewButton;
