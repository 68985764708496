import React from "react";
import Table from "../../../components/table/Table";
import diagnosticcategories from "./diagnosticcategories.json";
import { noDataPlaceholder } from "../../../constants";

const DiagnosticCategories = () => {
    const renderExamples = (row) => {
        if (row.Example === "") {
            return noDataPlaceholder;
        } else {
            const exampleContent =
                row.id === 22 ? (
                    <>
                        <div>{row.Example}</div>
                        <div>
                            *Please note that this code could be reassigned to a
                            related category if applicable over time.
                        </div>
                    </>
                ) : (
                    row.Example
                );

            return <div>{exampleContent}</div>;
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Diagnostic categories",
                columns: [
                    {
                        Header: "Code",
                        accessor: "Code",
                        disableSortBy: true,
                    },
                    {
                        Header: "Definition",
                        accessor: "Definition",
                        disableSortBy: true,
                        width: 200,
                    },
                    {
                        Header: "Examples",
                        accessor: (row) => renderExamples(row),
                        disableSortBy: true,
                        width: 400,
                    },
                ],
            },
        ],
        []
    );

    return (
        <div>
            <p className="diagnostic-paragraph pl-20p">
                The following table summarizes the diagnostic codes that are
                included in each diagnostic category:
            </p>
            <Table
                columns={columns}
                data={diagnosticcategories}
                responsiveConfig={{
                    type: "COLUMN",
                    striped: false,
                    screen: "md",
                }}
                getTrClasses={null}
                pagination={false}
                className="reference-table diagnostic"
            />
        </div>
    );
};

export default DiagnosticCategories;
