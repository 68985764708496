import { createSlice } from "@reduxjs/toolkit";

const viewReportSlice = createSlice({
    name: "reportOrganization",
    initialState: {
        selectedOrganizationsReports: {},
        selectedOrganizationsIdReports: {},
        selectedRelatedOrganizationsForReports: {},
    },
    reducers: {
        addSelectedOrganizationForReports(state, action) {
            state.selectedOrganizationsReports[action.payload.id] = {
                organization: action.payload.organization,
                related: action.payload.related,
            };
        },

        setSelectedOrgainzationsIdForReports(state, action) {
            state.selectedOrganizationsIdReports = action.payload;
        },

        setSelectedRelatedOrganizationsForReports(state, action) {
            state.selectedRelatedOrganizationsForReports = action.payload;
        },

        clearSelectedOrganizationsForReports(state) {
            state.selectedOrganizationsReports = {};
            state.selectedOrganizationsIdReports = {};
            state.selectedRelatedOrganizationsForReports = {};
        },
    },
});

export const {
    addSelectedOrganizationForReports,
    setSelectedOrgainzationsIdForReports,
    clearSelectedOrganizationsForReports,
    setSelectedRelatedOrganizationsForReports,
} = viewReportSlice.actions;

export default viewReportSlice.reducer;
