import React from "react";
import { MultiSelect } from "react-multi-select-component";
import SectionHeading from "../../components/SectionHeading";
import OrganizationLogo from "../../components/OrganizationLogo";
import close from "../../assets/icons/close-icon.svg";
import { useSelector } from "react-redux";

function RelatedOrganization({
    relatedOrganizations,
    selectedRelatedOrganizations,
    handleRelatedOrganizations,
    selectedOrganization,
    removeOrgPreference,

    organizationPreference,
}) {
    function handleSelect(organization) {
        handleRelatedOrganizations(organization);
    }

    const className =
        selectedRelatedOrganizations.length >= 1 &&
        selectedRelatedOrganizations.length !== relatedOrganizations.length
            ? selectedRelatedOrganizations.length >= 1 &&
              selectedRelatedOrganizations.length < 7
                ? "test"
                : "modify"
            : ",";

    const {totalParentOrganizations} = useSelector(
        (state) => state.organizations
    );
    return (
        <div className="row  mx-0">
            <OrganizationLogo
                organizationName={selectedOrganization?.ORGNIZATIONNAME}
                label={true}
                height={55.01}
            />
            <div
                className="section-card mb-20p col "
                style={{ backgroundColor: "#E6EEF4", padding: 24, width: 300 }}
            >
                <div className="d-flex">
                    <SectionHeading
                        heading={selectedOrganization?.ORGANIZATIONGROUPNAME}
                        tabIndex="0"
                    />
                    {(organizationPreference?.length > 0 && organizationPreference.toString() !== "") ||
                        (organizationPreference && organizationPreference !== undefined)
                        && totalParentOrganizations !== 1 && (
                            <img
                                className="closeicon ms-2 align-self-start"
                                src={close}
                                onClick={removeOrgPreference}
                                alt="closeIcon"
                                width="25px"
                            />
                        )}
                </div>

                <>
                    <label>Related organizations</label>

                    <div>
                        <MultiSelect
                            className={"select-dropdown " + className}
                            options={relatedOrganizations}
                            value={selectedRelatedOrganizations}
                            onChange={handleSelect}
                            labelledBy="Select"
                            disableSearch={false}
                            isCreatable={false}
                            overrideStrings={{
                                selectSomeItems: "Add related Organization +",
                            }}
                        />
                    </div>
                </>
            </div>
        </div>
    );
}

export default React.memo(RelatedOrganization);
