import ClaimsSearch from "../models/ClaimsSearch";
import ClaimsPaymentSearch from "../models/ClaimsPaymentSearch";
import PortalUserResponse from "../models/PortalUserResponse";
import config from "./../config";
import enterpriseInformationApiSlice from "./enterpriseInformationApiSlice";
import LeaveSearch from "../models/LeaveSearch";

const dataviewApiSlice = enterpriseInformationApiSlice.injectEndpoints({
    endpoints: (builder) => {
        return {
            getClaimsSearch: builder.query({
                query: ({ filters }) => ({
                    url: `claims/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        organizationId: filters.organizationId,
                        productGroupCd: filters.coverageType,
                        claimCoverageType: filters.claimCoverageTypes,
                        effectiveStartDt: filters.effectiveStartDate,
                        effectiveEndDt: filters.effectiveEndDate,
                        claimStatus: filters.claimStatus,
                        claimIds: filters.claimIds,
                        claimSourceSystemText: filters.claimSourceSystemText,
                        reportingGroup: filters.reportingGroup,
                        // groupAgreementDivision: filters.groupAgreementDivision,
                        individualAgreementIdentifier:
                            filters.individualAgreementIdentifier,
                        startIndex: filters.startIndex,
                        startPage: filters.startPage,
                        count: filters.count,
                    },
                }),
                invalidatesTags: (_) => ["claimsSearch"],
                transformResponse: (response, _meta, _arg) => {
                    return ClaimsSearch.format(response);
                },
            }),

            getClaimsPaymentSearch: builder.query({
                query: ({ filters }) => ({
                    url: `claims/${process.env.REACT_APP_API_VERSION}/payments${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        effectiveStartDt: filters.effectiveStartDate,
                        effectiveEndDt: filters.effectiveEndDate,
                        organizationId: filters.organizationId,
                        productGroupCd: filters.coverageType,
                        claimCoverageType: filters.claimCoverageTypes,
                        paymentStatus: filters.paymentStatus,
                        // groupAgreementDivision: filters.groupAgreementDivision,
                        // reportingGroup: filters.reportingGroup,
                        startIndex: filters.startIndex,
                        startPage: filters.startPage,
                        count: filters.count,
                    },
                }),
                invalidatesTags: (_) => ["claimsPaymentSearch"],
                transformResponse: (response, _meta, _arg) => {
                    return ClaimsPaymentSearch.format(response);
                },
            }),
            getLeavesSearch: builder.query({
                query: ({ filters }) => ({
                    url: `leaves/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        organizationId: filters.organizationId,
                        coverageType: filters.coverageType,
                        effectiveStartDt: filters.effectiveStartDate,
                        effectiveEndDt: filters.effectiveEndDate,
                        startIndex: filters.startIndex,
                        startPage: filters.startPage,
                        count: filters.count,
                        leaveStatus: filters.leaveStatus,
                        leaveScheduleType: filters.leaveScheduleType,
                        historicalLeaveInd: filters.historicalLeaveInd,
                        // reportingGroup: filters.reportingGroup,
                        leavePeriodStatus: filters.leavePeriodStatus,
                        protectionStatus: filters.protectionStatus,
                    },
                }),
                invalidatesTags: (_) => ["LeavesSearch"],
                transformResponse: (response, _meta, _arg) => {
                    return LeaveSearch.format(response);
                },
            }),

            getPortalUserInfo: builder.query({
                query: ({ isfUserId, organizationId }) => ({
                    url: `portal-users/${process.env.REACT_APP_API_VERSION}/${isfUserId}${config.REACT_APP_ENV_NAME}`,
                    method: "GET",
                    params: {
                        organizationId: String(organizationId).toUpperCase(),
                        serviceIdentifier: "CLAIMLEAVEREPORTING",
                    },
                }),
                invalidatesTags: (_) => ["portalUserInfo"],
                transformResponse: (response, _meta, _arg) => {
                    return PortalUserResponse.format(response);
                },
            }),
        };
    },
    overrideExisting: false,
});

export const {
    useLazyGetClaimsSearchQuery,
    useLazyGetClaimsPaymentSearchQuery,
    useLazyGetPortalUserInfoQuery,
    useLazyGetLeavesSearchQuery,
} = dataviewApiSlice;

export default dataviewApiSlice;
