import React from "react";
import ReportTable from "./ReportTable";

function ReportViewData({
    organizationIDISF,
    selectedReport,
    reportData,
    dataViewType,
    reportViewInformation,
    onViewOptionChange,
    onViewReportSave,
    allViewsData,
    loaderStatus,
    onViewReportUpdate,
    notificationStatus,
    reportTableData,
    isFetching,
    isUninitialized,
    isError,
    handleCoverageType,
    handleViewID,
    reportLastFetched,
    userEmail,
    filterValues,
    initialFiltersValue,
    portalUserApiError,
    organizationsAccess,
    emitFiltersDataForSelectedView,
    selectedFilterStatus,
    reportingPeriodValue,
    reportingStatusValue,
    selectedCoverageType,
    selectedPolicyDivision,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    periodStatusValue,
    protectionStatusValue,
    disclaimer,
    totalResults,
    isFetchingMore,
    portalUserData,
    totalPages,
    pagecounter,
}) {
    const handleOptionChange = (selectedOption) => {
        onViewOptionChange(selectedOption);
    };

    const handleViewReportSave = (item1, item2, item3, item4) => {
        onViewReportSave(item1, item2, item3, item4);
    };

    const handleViewReportUpdate = (item1, item2, item3, item4, item5) => {
        onViewReportUpdate(item1, item2, item3, item4, item5);
    };

    const sortingDtFn = (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);

        const parseValue = (value) => {
            const dateV = new Date(value);
            return dateV;
        };

        const a = parseValue(valueA);
        const b = parseValue(valueB);

        return a > b ? 1 : a < b ? -1 : 0;
    };
    const columnsData = () => {
        return reportViewInformation[0].ColumnInformation.map((obj, index) => {
            if (String(obj.id).toLocaleLowerCase().includes("date")) {
                return { ...obj, sortingFn: sortingDtFn };
            }
            return index !== null ? { ...obj, sortingFn: "text" } : obj;
        });
    };
    return (
        <ReportTable
            defaultColumns={columnsData()}
            allViewsData={allViewsData}
            claimData={reportTableData || []}
            handleOptionChange={handleOptionChange}
            isFetching={isFetching}
            isUninitialized={isUninitialized}
            isError={isError}
            organizationId={organizationIDISF}
            selectedReportView={selectedReport}
            reportData={reportData}
            dataViewType={dataViewType}
            handleViewReportSave={handleViewReportSave}
            loaderStatus={loaderStatus}
            handleViewReportUpdate={handleViewReportUpdate}
            handleCoverageType={handleCoverageType}
            notificationStatus={notificationStatus}
            description={reportViewInformation[0].Description}
            selectedViewId={reportViewInformation[0].ViewId}
            handleViewID={handleViewID}
            reportViewType={reportViewInformation[0].DataViewType}
            reportLastFetched={reportLastFetched}
            userEmail={userEmail}
            filterValues={filterValues}
            initialFiltersValue={initialFiltersValue}
            portalUserApiError={portalUserApiError}
            organizationsAccess={organizationsAccess}
            emitFiltersDataForSelectedView={emitFiltersDataForSelectedView}
            selectedFilterStatus={selectedFilterStatus}
            reportingPeriodValue={reportingPeriodValue}
            reportingStatusValue={reportingStatusValue}
            selectedCoverageType={selectedCoverageType}
            selectedPolicyDivision={selectedPolicyDivision}
            includeHistoricalLeaveValue={includeHistoricalLeaveValue}
            periodScheduleTypeValue={periodScheduleTypeValue}
            periodStatusValue={periodStatusValue}
            protectionStatusValue={protectionStatusValue}
            reportSortingColumnName={
                reportViewInformation?.[0]?.SortOrder?.ColumnDisplayOrderId
                    ? columnsData()[
                          reportViewInformation[0].SortOrder
                              .ColumnDisplayOrderId - 1
                      ]?.id
                    : []
            }
            reportSortingColumnDirection={
                reportViewInformation?.[0]?.SortOrder?.Direction === "ASC"
                    ? false
                    : true
            }
            disclaimer={disclaimer}
            totalResults={totalResults}
            isFetchingMore={isFetchingMore}
            portalUserData={portalUserData}
            totalPages={totalPages}
            pagecounter={pagecounter}
        />
    );
}

export default React.memo(ReportViewData);
