import React from "react";

const TroubleshootingIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.5 15C20.625 15 24 11.6719 24 7.5C24 6.79688 23.8594 6.09375 23.6719 5.4375C23.5312 4.92188 22.9219 4.82812 22.5469 5.20312L18.9375 8.8125C18.7969 8.95312 18.6094 9 18.4219 9H15.75C15.3281 9 15 8.67188 15 8.25V5.57812C15 5.39062 15.0469 5.20312 15.1875 5.0625L18.7969 1.45312C19.1719 1.07812 19.0312 0.46875 18.5625 0.328125C17.9062 0.140625 17.2031 0 16.5 0C12.3281 0 9 3.375 9 7.5C9 8.4375 9.14062 9.28125 9.42188 10.0781L0.890625 18.6094C0.328125 19.1719 0 20.0156 0 20.8594C0 22.5938 1.40625 24 3.14062 24C3.98438 24 4.82812 23.6719 5.39062 23.1094L13.9219 14.5781C14.7188 14.8594 15.5625 15 16.5 15ZM3.75 21.375C3.09375 21.375 2.625 20.9062 2.625 20.25C2.625 19.6406 3.09375 19.125 3.75 19.125C4.35938 19.125 4.875 19.6406 4.875 20.25C4.875 20.9062 4.35938 21.375 3.75 21.375Z"
                fill="#037CB7"
            />
        </svg>
    );
};

export default TroubleshootingIcon;
