import React, { useEffect } from "react";
import SectionHeading from "../../components/SectionHeading";
import { useNavigate } from "react-router-dom";
import leaveIcon from "../../assets/icons/leave-icon.svg";
import changesIcon from "../../assets/icons/changes-icon.svg";
import absenceIcon from "../../assets/icons/absence-icon.svg";
import lifeView from "../../assets/icons/report-life-icon.svg";
import paymentHistory from "../../assets/icons/payment-history-icon.svg";
import disabilityIcon from "../../assets/icons/disability-icon.svg";
import FrequentlyUsedViews from "./FrequentlyUsedViews";
import Loader from "../../components/ui/Loader";
import { useSelector } from "react-redux";
import { useLazyGetReportDetailsQuery } from "../../slices/saveReportViewSlice";
import { useLazyGetPortalUserInfoQuery } from "../../slices/dataviewApiSlice";
import { useFlags } from "launchdarkly-react-client-sdk";
import GTM from "../../GTM";
import {
    DATAVIEWTYPE,
    getSelectedIsfOrganizationIds,
} from "../../utils/helpers";

const ReportCard = ({
    title,
    icon,
    path,
    button,
    text,
    displayCards,
    className,
    data,
    portalUserData,
    isError,
}) => {
    const gtm = new GTM(null);
    const navigate = useNavigate();
    const { displayDataViewTypes } = useFlags();

    const dataViewTypes = displayDataViewTypes.split(",");
    const handleClick = () => {
        const selectedReportTypeData = data.filter((obj) => {
            if (obj.DataViewType.includes(path.slice(0, 4))) {
                return obj.Name;
            }
        });
        navigate(`/reportingcapabilities/${path}`, {
            state: { selectedReportTypeData, title, portalUserData, isError },
        });
        gtm.Event(
            "run reports",
            "button",
            "click",
            title.toLowerCase(),
            "reports",
            "",
            "report_card_click",
            ""
        );
    };

    const handleKeyPress = (event) => {
        const selectedReportTypeData = data.filter((obj) => {
            if (obj.DataViewType.includes(path.slice(0, 4))) {
                return obj.Name;
            }
        });
        if (event.key === "Enter" || event.keyCode === 13) {
            navigate(`/reportingcapabilities/${path}`, {
                state: {
                    selectedReportTypeData,
                    title,
                    portalUserData,
                    isError,
                },
            });
        }
    };

    return (
        <div
            className=" section-card mb-0p report-card"
            onClick={handleClick}
            onKeyPress={handleKeyPress}
            tabIndex={0}
            style={
                displayCards
                    ? dataViewTypes.includes(path)
                        ? {}
                        : { pointerEvents: "none", opacity: "0.4" }
                    : {}
            }
        >
            <div className="d-flex" style={{ gap: 8, alignItems: "center" }}>
                <div className={`${button} icon`}>
                    {" "}
                    <img src={icon} alt={title} />
                </div>{" "}
                <SectionHeading heading={title} mb="mb-0p" />
            </div>

            <span className="d-flex flex-direction" style={{ paddingTop: 5 }}>
                <p className={className} style={{ marginBottom: button }}>
                    {text}
                </p>
                <span className="a-self">
                    <button className="d-flex button">
                        View {button}
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </span>
            </span>
        </div>
    );
};

const ReportViews = ({ displayReportView, organizations, user }) => {
    const navigate = useNavigate();
    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrganizations,
    } = useSelector((state) => state.reportOrganization);

    const { selectedIsfOrganizationIds, organizationGroupId } =
        getSelectedIsfOrganizationIds(
            userSelectedOrganizationReports,
            reportId,
            selectedRelatedOrganizations
        );

    const { dlselectedIsfOrganizationId, dlViewId, dlDataView } = useSelector(
        (state) => state.auth
    );

    const [
        getPortalUser,
        { data: portalUserData, isError, isFetching: isFetchingUserData },
    ] = useLazyGetPortalUserInfoQuery();

    const [getReportDetails, { data: data, isFetching }] =
        useLazyGetReportDetailsQuery();

    const reportDetailsParams = {
        isfUserId: user.userId,
        dataViewType: DATAVIEWTYPE,
        selectedIsfOrganizationIds: selectedIsfOrganizationIds,
    };

    // Include organizationGroupId if it's defined
    if (organizationGroupId) {
        reportDetailsParams.organizationGroupId = organizationGroupId;
    }
    useEffect(() => {
        if (displayReportView || organizations.length === 1) {
            getReportDetails(reportDetailsParams);
            getPortalUser({
                isfUserId: String(user.userId).toUpperCase(),
                organizationId: [selectedIsfOrganizationIds], //TODO: need to test if comma separated actually works once EDI updates their API
            });
        }
    }, [displayReportView, organizations.length]);
    useEffect(() => {
        if (data && dlselectedIsfOrganizationId && dlViewId && dlDataView) {
            const selectedReportTypeData = data.filter((obj) =>
                obj.DataViewType.includes(dlDataView.slice(0, 4))
            );

            const selectedReport = data.find((item) => item.ViewId == dlViewId);

            if (selectedReportTypeData.length > 0) {
                navigate(`/reportingcapabilities/${dlDataView}`, {
                    state: {
                        selectedReport,
                        selectedReportTypeData,
                        title: dlViewId,
                        portalUserData: portalUserData?.userPermissions,
                        isError,
                    },
                });
            }
        }
    }, [
        data,
        dlselectedIsfOrganizationId,
        dlViewId,
        dlDataView,
        navigate,
        portalUserData,
        isError,
    ]);

    const reportItems = [
        {
            title: "Absence",
            icon: absenceIcon,
            path: "Absence",
            button: "absence",
            text: "A detailed snapshot of all employee absences for disability and leave",
        },
        {
            title: "Leave",
            icon: leaveIcon,
            path: "Leave",
            button: "leave",
            text: "Review detailed information on all leaves for your organization",
            className: "leave-content",
        },
        {
            title: "Disability",
            icon: disabilityIcon,
            path: "Disability",
            button: "disability",
            text: "Details on short and long term disability claims",
            className: "disability-content",
        },
        {
            title: "Life",
            icon: lifeView,
            path: "Life",
            button: "life",
            text: "Review claim information for Life and Life Waiver of Premium",
            className: "lifeview",
        },
        {
            title: "Payment history",
            icon: paymentHistory,
            path: "PaymentHistory",
            button: "payment history",
            text: "Explore payment activity across claims and paid leaves ",
            className: "payment-history",
        },
        {
            title: "Changes",
            icon: changesIcon,
            path: "Changes",
            button: "changes",
            text: "Review changes for specific time periods for your organization",
            className: "changes-content",
        },
    ];

    return (
        <div>
            {isFetching || isFetchingUserData ? (
                <Loader />
            ) : (
                <div
                    className="report-views"
                    style={
                        displayReportView || organizations.length === 1
                            ? {}
                            : {
                                  pointerEvents: "none",
                                  opacity: "0.4",
                                  gridColumn: 1 / 3,
                                  gridRow: 1 / 4,
                              }
                    }
                >
                    {reportItems.map((item) => (
                        <div key={item.path}>
                            <ReportCard
                                key={item.path}
                                {...item}
                                data={data}
                                portalUserData={portalUserData?.userPermissions}
                                displayCards={displayReportView}
                                isError={isError}
                            />
                        </div>
                    ))}

                    <FrequentlyUsedViews
                        data={data}
                        isFetching={isFetching}
                        isError={isError}
                        portalUserData={portalUserData?.userPermissions}
                    />
                </div>
            )}
        </div>
    );
};

export default ReportViews;
