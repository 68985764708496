import React from "react";

function TotalLeaveIcon() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 24C18.6094 24 24 18.6562 24 12C24 5.39062 18.6094 0 12 0C5.34375 0 0 5.39062 0 12C0 18.6562 5.34375 24 12 24ZM12 6C12.6094 6 13.125 6.51562 13.125 7.125V12.375C13.125 13.0312 12.6094 13.5 12 13.5C11.3438 13.5 10.875 13.0312 10.875 12.375V7.125C10.875 6.51562 11.3438 6 12 6ZM13.5 16.5C13.5 17.3438 12.7969 18 12 18C11.1562 18 10.5 17.3438 10.5 16.5C10.5 15.7031 11.1562 15 12 15C12.7969 15 13.5 15.7031 13.5 16.5Z"
                fill="#037CB7"
            />
        </svg>
    );
}

export default TotalLeaveIcon;
