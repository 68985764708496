import React from "react";
import { useNavigate } from "react-router-dom";
import GTM from "../GTM";
import { BreadcrumbPortal } from "./ui/Breadcrumb";
import { useSelector } from "react-redux";

function SubHeader() {
    let url = window.location.pathname;
    return (
        <div className="subheader">
            <div className="container">
                <div className="row">
                    <div className="col-md-12" style={{ padding: 0 }}>
                        <div className="breadcrumbs d-flex justify-content-between align-items-center px-24p">
                            <BreadcrumbPortal />
                            {url !== "/preferences" && <BackButton />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const BackButton = React.memo(() => {
    const navigate = useNavigate();
    const { totalParentOrganizations } = useSelector(
        (state) => state.organizations
    );
    const gtm = new GTM(null);
    const regX = /organization\/[^\/]+\/employees\/search/s;
    const reportRegX = /reports\/[a-z]+/gm;
    let url = window.location.pathname;
    const standard = url.split("/");
    let gtmLabel = "";
    if (url === "/search") {
        gtmLabel = "search";
    } else if (url.match(regX) !== null) {
        gtmLabel = "employee search";
    } else if (url === "/reports") {
        gtmLabel = "reports";
    } else if (reportRegX !== null && standard.includes("standard")) {
        gtmLabel = "standared report";
    } else if (url === "/help") {
        gtmLabel = "help";
    } else if (url === "/help/faq") {
        gtmLabel = "frequently asked questions";
    } else {
        gtmLabel = "claim & leave";
    }
    const checkOrganizationLength =
        totalParentOrganizations > 1
            ? url !== "/search"
            : !url.includes("employees");
    const isBackEnabled =
        window.history.state &&
        url !== "/reports" &&
        checkOrganizationLength &&
        url !== "/dashboard" &&
        url !== "/help" &&
        url !== "/reportingcapabilities";
    return (
        <button
            data-testid="backLink"
            style={{
                border: "none",
                background: "none",
            }}
            className={`d-flex align-items-center text-bold back-btn ${
                isBackEnabled || window.history.state.idx > 2
                    ? "enabled"
                    : "disabled"
            }`}
            onClick={() => {
                if (isBackEnabled || window.history.state.idx > 2) {
                    navigate(-1);
                    gtm.Event(
                        gtmLabel,
                        "button",
                        "click",
                        "back",
                        gtmLabel,
                        "",
                        "navigation_click",
                        ""
                    );
                }
            }}
        >
            <i className="fa-solid fa-chevron-left"></i>
            <span className="ml-10p">Back</span>
        </button>
    );
});

export default SubHeader;
