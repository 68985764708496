import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";
import { Modal } from "react-bootstrap";
import releaseLogo from "../assets/icons/New Feature.svg";
import {
    clearReleaseMessage,
    setReleaseMessage,
} from "../slices/preferenceSlice";
import { Parser } from "html-to-react";

const ReleaseMessage = ({ releaseHeader, releaseContent, releaseLink }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const htmlToReactParser = new Parser();

    const [show, setShow] = useState(true);

    const clearAndClose = () => {
        setShow(false);
        dispatch(clearReleaseMessage());
    };

    const handleClose = () => {
        clearAndClose();
    };

    const handleLearnMore = () => {
        if (releaseLink) {
            navigate(new URL(releaseLink).pathname);
        }
        clearAndClose();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            clearAndClose();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            size="lg"
            backdrop="static"
            className="Release-Message"
        >
            <Modal.Header className="d-flex justify-content-end">
                <div className="cursor-pointer" onClick={handleClose}>
                    <i className="fa-solid fa-xmark"></i>
                    Close
                </div>
            </Modal.Header>
            <Modal.Body>
                <img src={releaseLogo} alt="New Feature Logo" />
                <div className="text-center">
                    <label className="first-login-text">{releaseHeader}</label>
                    <div className="release-content">
                        {htmlToReactParser.parse(releaseContent)}
                    </div>
                    <div className="first-login-buttons">
                        <Button onClick={handleLearnMore} text="Learn more" />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ReleaseMessage;
