import React from "react";

const UserGuide = () => {
    return (
        <>
            <div className="diagnostic-paragraph">Introduction to search and reports</div>
            <div className="d-flex justify-content-center pt-16p pb-20p">
                <iframe
                    className="user-guide"
                    src="https://player.vimeo.com/video/989228792?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture allowFullScreen"
                    title="User Guide Video"
                ></iframe>
            </div>
        </>
    );
};

export default UserGuide;
