import React from "react";

function ScrollToTopIcon() {
    const matchUrl = /reports\/[a-z]+\/[a-z]+\/\d/gm;
    const url = window.location.pathname;

    let urlDisplay = url.match(matchUrl);

    return (
        <div
            className="scroll-to-top d-none"
            id="scroll-to-top"
            tabIndex={0}
            role="button"
            style={{ visibility: urlDisplay === null ? "" : "hidden" }}
        >
            <div className="d-flex flex-column align-items-center">
                <i
                    className="fa-solid fa-arrow-up"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }}
                ></i>
                <span className="fs-sm d-block mt-10p">Back To Top</span>
            </div>
        </div>
    );
}

export default ScrollToTopIcon;
