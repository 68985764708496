import { React, useEffect, useRef } from "react";
import GTM from "../../GTM";

function NoRecords({
    message,
    className = "",
    timestamp = null,
    page,
    label,
    attribute1,
    attribute3,
}) {
    const ref = useRef(null);
    const gtm = new GTM(null);

    useEffect(() => {
        ref.current.focus();

        setTimeout(() => {
            gtm.Event(
                page,
                "field level error",
                "error",
                label,
                attribute1,
                "",
                attribute3,
                `${
                    message ??
                    "no records found, please review your search criteria and try again."
                }`
            );
        }, 6000);
    }, [timestamp]);

    return (
        <div
            className={"alert notify-norecords py-20p " + className}
            ref={ref}
            tabIndex={0}
        >
            <p>
                {message ??
                    "No records found, please review your search criteria and try again."}
                <span className="icon-notify">i</span>
            </p>
        </div>
    );
}

export default NoRecords;
